import { useState, useEffect } from "react";
import PropTypes from "prop-types";

import "./style/ManageAccount.css";

function ManageAccount(props) {
	const [Source, setSource] = useState("");
	const [isClicked, setIsClicked] = useState(false);

	const handleClick = () => {
		setIsClicked(!isClicked);
	};

	useEffect(() => {
		if (props.source !== "") setSource(props.source);
	});

	return (
		<>
			<div className="target" onClick={handleClick}>
				{Source ? (
					<img src={Source} alt="profile icon" className="conti rotate" />
				) : (
					<div className="cont">{props.name[0]}</div>
				)}
			</div>
			<div className={`profile ${isClicked ? "active-ma" : ""}`}>
				<div className="profilecont rotate">
					{Source ? (
						<img src={Source} alt="profile icon" />
					) : (
						<div className="cont">{props.name[0]}</div>
					)}
					<div className="profileInfo">
						<div className="name">{props.name}</div>
						<div className="email">
							{props.mail}
						</div>
					</div>
				</div>
				<div className="abuttons rotate">
					{/* <button className="b">Manage Account</button> */}
					<button className="b" onClick={props.onClick}>Log Out</button>
				</div>
			</div>
		</>
	);
}

ManageAccount.propTypes = {
	source: PropTypes.string,
	name: PropTypes.string,
	mail: PropTypes.string,
	onClick: PropTypes.func,
};

export default ManageAccount;
