// React Packages
import { React, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";

// Material UI Packages
import {
  Grid,
  TextField,
  Checkbox,
  Button,
  FormGroup,
  FormControlLabel,
} from "@mui/material/";

// AntD UI Components
import { message } from "antd";

// Javascript Packages
import Cookies from "js-cookie";

// LMA Packages
import ImageContainer from "../otpVerification/containerImg";

// Services
import { verifyotpService } from "../../../service/auth/verifyotpService";

function OtpVerification() {
  const [otpnumber, setotpnumber] = useState();

  const navigate = useNavigate();
  const location = useLocation();
  const handleSubmit = async (event) => {
    const verifyOtpResponse = await verifyotpService(
      Number(location.state.userid),
      Number(otpnumber)
    );
    console.log(verifyOtpResponse);
    if (verifyOtpResponse === "OTP deleted") {
      message.success("Your otp successfully verified");
      navigate("/login");
    }
  };

  return (
    <div id="master-wraper">
      <div className="left-wraper">
        <ImageContainer />
      </div>
      <div className="right-wraper">
        <div className="login-box">
          {/* Text Box */}
          <div className="text-box">
            <h1>OTP Verification</h1>
            <p className="sub-heading">
              Please check your Email and Enter the OTP to Continue!
            </p>
          </div>
          {/* Input Fields */}
          <div className="contant-wraper">
            {/* <div className="">please check your mail, where you would have recieved the otp for your email verification</div> */}
            <Grid container spacing={1} display="flex" justifyContent="center">
              <Grid item xs={12} sm={13}>
                <TextField
                  required
                  fullWidth
                  className="fieldset"
                  sx={{ input: { textAlign: "center" } }}
                  value={otpnumber}
                  onChange={(e) => setotpnumber(e.target.value)}
                />
              </Grid>
            </Grid>
            {/* <Link to="/forget-password">
              <span className="text-2">Resend the OTP?</span>
            </Link> */}
          </div>
          {/* Submit Button */}

          <Button
            variant="contained"
            className="full-btn"
            onClick={() => {
              handleSubmit();
            }}
          >
            Verify the OTP
          </Button>
        </div>
      </div>
      <div className="clear"></div>
    </div>
  );
}
export default OtpVerification;
