import {React, useState} from 'react';

import './style/Introduction1.css';

import { useNavigate } from 'react-router-dom';

// Assets
import logo from '../../assets/global/logo.png';
import banner from '../../assets/intro/Jana-Gana-Mana-Web-Banner.png';

// Components
import { IntroButton } from './components/IntroButton';
import { TextField, Button } from "@mui/material/";

// AntD UI Components
import { message } from 'antd';

// Services
import { signUpService } from "../../service/auth/signUpService";

const Introduction1 = () => {

    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const handleMenuClick = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    // Registeration:

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setconfirmPassword] = useState();
    const [country, setCountry] = useState();
    const [city, setCity] = useState();
    const [dob, setDOB] = useState();

    const handleSubmit = async (event) => {
        const userData = { 
          firstName: firstName,
          middleName: "",
          lastName: lastName,
          email: email, 
          mobile: mobile,
          password: password,
          confirmPassword: confirmPassword,
          country: country,
          city: city,
          dob: dob,
        }
  
        if(firstName === undefined ||
          lastName === undefined ||
          email === undefined ||
          mobile === undefined ||
          password === undefined ||
          confirmPassword === undefined ||
          country === undefined ||
          city === undefined ||
          dob === undefined){
            message.error("Fill all the required fields!");
          }else{
  
            const data = await signUpService(userData);
           
            navigate("/verifyotp", {state: {
              userid: data.id
            }
          });
        }
      }


    return (
        <>
            <div id="Master-Intro-Wrapper">
                <div id="NavBar-Intro-Wrapper">
                    <div id="NavBar-Intro-Wrapper-Desktop">
                        <div className='logo-1'>
                            <img id="Intro-logo" alt='Lorraine Music Academy Logo' src={logo} />
                        </div>
                        <div id="Nav-Intro-Items">
                            <span onClick={()=>{navigate("/register")}}>Register</span>
                            <span onClick={()=>{window.open( "https://www.lorrainemusicacademy.com","_blank")}}>About Us</span>
                            <span onClick={()=>{navigate("/login")}}><IntroButton id="loginButton-Intro" value="Login"/></span>
                        </div>
                    </div>
                </div>

                {/* Banner Container */}
                <div id="Intro-Banner" onClick={()=>{navigate("/login")}}>
                    <img alt='Jana Gana Mana Banner' src={banner}/>
                </div>

                 {/* Into Content */}
                <div id="Intro-Content">
                    <h2>Embark on a Patriotic Journey: <br />Master the National Anthem and Unlock your Certificate of Responsibility.</h2>
                </div>

                {/* Form */}
                <div id="Intro-form">
                    <p id="form-heading">Register Yourself Today!</p>
                    <div id="Intro-form-fields">
                        <div>
                            <TextField label="First Name" className="fieldset1" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
                            <TextField label="Email" className="fieldset1" value={email} onChange={(e) => setEmail(e.target.value)}/>
                            <TextField label="City" className="fieldset1" value={city} onChange={(e) => setCity(e.target.value)}/>
                            <TextField label="Password" className="fieldset1" value={password} onChange={(e) => setPassword(e.target.value)}/>
                        </div>
                        <div>
                            <TextField label="Last Name" className="fieldset1" value={lastName} onChange={(e) => setLastName(e.target.value)}/>
                            <TextField label="Phone No" className="fieldset1" value={mobile} onChange={(e) => setMobile(e.target.value)}/>
                            <TextField label="Country" className="fieldset1" value={country} onChange={(e) => setCountry(e.target.value)}/>
                            {/* <TextField label="School Name" className="fieldset1" value={firstName} onChange={(e) => setFirstName(e.target.value)}/> */}
                            <TextField label=" Confirm Password" className="fieldset1" value={confirmPassword} onChange={(e) => setconfirmPassword(e.target.value)}/>
                        </div>
                    </div>
                    <div id="btn-contanier">
                        <Button id="form-btn" onClick={handleSubmit}>Register</Button>
                    </div>
                </div>

                <div id="Intro-Content-note">
                    <span>PLEASE USE A LAPTOP TO ACCESS THE DASHBOARD FOR BEST RESULTS.</span>
                </div>

                <div id="Intro-Content">
                    <span onClick={()=>{navigate("/login")}}><IntroButton id="loginButton-Intro" value="Login"/></span>
                </div>

                <div id="Intro-Content-anthem">
                    <h1 id="Intro-Content-h1">An Anthem for "Jana Gana Mana"</h1>
                </div>

                <div className='columns'>

                    {/* Video */}

                    <div id="Intro-Video-Player">
                        <iframe width="100%" height="80%" src="https://www.youtube.com/embed/U6x41v3SXw0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                    </div>


                    {/* Paragraph */}
                    <div id='Intro-Paragraph'>
                        <p>India is young! India’s biggest asset is its young population. The spirit and motivation of the youth of this country will determine the direction the economy takes and help turn India’s fortunes. They will help make a big difference in the way India finds its true place as one of the world’s leading and influential nations during the next couple of decades. They will prove pivotal in making India a world leader in the coming years.</p>
                        <p>We aim to rekindle and build a National fervour of <span>Patriotism</span> and motivate the youth to build their lives positively to contribute to India. India is a nation with a multi-faceted and diverse cultural heritage. We can build solidarity through appreciation of our cultural diversity, acting on the principle that the understanding and appreciation of diversity is a powerful unifier – that our most important similarities, human common ground, are to be found in our distinctive histories of expression. Nowhere is this potential for positive fusion more evident than in India. Through this <span>National Patriotic Project</span>, we will empower our children and youth to build on the nation’s cultural ethos of <span>“Unity in Diversity”</span>.</p>
                        <p><span>Music</span> is as important to society as reading, writing, and arithmetic, not just as a disposable extra. Exposure to music and art has a profound impact on individuals. In selecting the first song that could be re-taught to the nation, we chose the one song that is understood and sung by the whole nation, our <span>National Anthem.</span> Other songs will also follow. When this musical imagination and experience is ignited in a group circumstance, we have the most extraordinary power to change young lives for positive living.</p>
                    </div>

                </div>

                {/* Footer */}

                <div id='Intro-Footer'>
                    <span>Copyright © 2009-{new Date().getFullYear()} | Lorraine Music Academy® Pvt. Ltd. | All rights reserved.</span>
                </div>
            </div>
        </>
    );
}

export default Introduction1;