import { React } from 'react';

// Importing Images
import Logo from '../../../assets/global/logo.png'; 
import register from '../../../assets/auth/Login/janaganamana-register.png';

// styling Sheet
import '../../../App.css';

function ImageContainer() {
    return (
        <>
            <div className='logo'>
                    <img src={Logo} alt="logo" />
                </div>
                <div className='left-image'>
                <img src={register} alt='Register'/>
            </div>
        </>
    );
}

export default ImageContainer;