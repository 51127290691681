import axios from "axios";
import { DEV_COURSE_URL } from "../../constant";
export const urlservice = async (coursesid, videoname) => {
  try {
    const integerindex = videoname.indexOf(String(coursesid));
    const withoutinteger = videoname.slice(
      integerindex + String(coursesid).length
    );
    const formData = new FormData();
    formData.append("videoname", withoutinteger);
    const response = await axios.post(
      `${DEV_COURSE_URL}/api/anthems/fetch-url/` + coursesid,
      formData
    );
    return response.data;
  } catch (error) {}
};
