/* eslint-disable react-hooks/exhaustive-deps */
import "./styles/result.css";
import PropTypes from "prop-types";
import { useState, useEffect } from "react";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import { markVideoCompleted } from "../../../../../service/dripFeeding/MarkVideoCompleted";

function Result(props) {
	const [width, setWidth] = useState(0);
	const EvaulatedKey = (masterNotes, evaluatedNotes) => {
		let correctNotes = [];
		for (let i = 0; i < masterNotes.length; i++) {
			if (masterNotes[i] === evaluatedNotes[i]) {
				correctNotes.push(true);
			} else {
				correctNotes.push(false);
			}
		}
		return correctNotes;
	};
	const greenNotesFinder = (masterNotes, evaluatedNotes) => {
		let greenNotesCounter = 0;
		for (let i = 0; i < masterNotes.length; i++) {
			if (masterNotes[i] === evaluatedNotes[i]) {
				greenNotesCounter = greenNotesCounter + 1;
			}
		}
		return greenNotesCounter;
	};
	const keys = EvaulatedKey(props.actualNotes, props.notesPlayed);
	const greenNotesCount = greenNotesFinder(
		props.actualNotes,
		props.notesPlayed
	);
	useEffect(() => {
		setWidth(Math.ceil((greenNotesCount / props.actualNotes.length) * 100));
		if (
			Math.ceil((greenNotesCount / props.actualNotes.length) * 100) > 70
		) {
			const decode = jwtDecode(Cookies.get("authToken"));
			const videosDetails = {
				studentId: decode.userId,
				videoId: props.pieceid + 1,
				courseId: 1,
			};
			markVideoCompleted(videosDetails).then(() => {
				alert("Congratulation you have unlocked the next Lesson");
			});
		}
	}, [greenNotesCount]);
	return (
		<div className='Result'>
			<h2 className='ResultHeading'>{props.title}</h2>
			<hr />
			{/* the perdicted notes are listed */}
			<div className='Prediction'>
				<div>Notes You Played : </div>
				<div className='NoteLists'>
					{props.notesPlayed.map((notes, index) => {
						return keys[index] ? (
							<div className='note right'>{notes}</div>
						) : (
							<div className='note wrong'>{notes}</div>
						);
					})}
				</div>
			</div>
			<hr />
			{/* the master notes are listed */}
			<div className='MasterNotes'>
				<div>Master Notes : </div>
				<div className='NoteLists'>
					{props.actualNotes.map((notes) => {
						return <div className='note'>{notes}</div>;
					})}
				</div>
			</div>
			<hr />
			{/* below we have the progress bar */}
			<div className='AssignmentStatus'>
				<div>
					Assignment Status:{" "}
					<b>
						{Math.ceil(
							(greenNotesCount / props.actualNotes.length) * 100
						)}
						%
					</b>
				</div>
				<div
					className='progressBar'
					style={{ "--width": `${width - 1}%` }}></div>
			</div>
		</div>
	);
}

Result.propTypes = {
	title: PropTypes.string.isRequired,
	notesPlayed: PropTypes.array.isRequired,
	actualNotes: PropTypes.array.isRequired,
	accuracy: PropTypes.any.isRequired,
};

export default Result;
