import { Dashboard, LibraryMusic, AccountBox, MusicVideo, BugReport } from '@mui/icons-material';


export const SideBarData = [
    {
      icon: Dashboard,
      heading: "Dashboard",
      to: "/home"
    },
    {
      icon: LibraryMusic,
      heading: "Courses",
        to: "/home/package"
    },
    // {
    //   icon: AccountBox,
    //   heading: "Profile",
    //   to: "/home/profile"
    // },
  ];