import React, { useState, useEffect } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { BiLoaderAlt } from "react-icons/bi";
import { urlservice } from "../../service/urlservice/UrlService";
import ReactPlayer from "react-player";
import Cookies from "js-cookie";

import "./styles.css";

export default function VideoPlayerPopup(props) {
	const [modal, setModal] = useState(false);
	const [response, setresp] = useState("");
	const openModal = () => {
		setModal(!modal);
	};

	useEffect(() => {
		const coursesid = Cookies.get("coursesid");
		// const videoname = Cookies.get("videoname");
		// now we are taking the videosname from the props
		urlservice(coursesid, props.videosname).then(setresp);
	}, [props.videosname]);

	return (
		<div className='VideoPlayerPopup'>
			<button
				disabled={props.isUnlocked ? false : true}
				onClick={openModal}
				className='VideoPlayerButton'>
				Play Video
				{modal ? (
					<section className='modal__bg'>
						<div className='modal__align'>
							<div className='modal__content' modal={modal}>
								<IoCloseOutline
									className='modal__close'
									arial-label='Close modal'
									onClick={setModal}
								/>
								<div className='modal__video-align'>
									<div className='modal__spinner'>
										<BiLoaderAlt
											className='modal__spinner-style'
											fadeIn='none'
										/>
									</div>
									<ReactPlayer
										config={{
											file: {
												attributes: {
													controlsList: "nodownload",
												},
											},
										}} // disable download button
										onContextMenu={(e) =>
											e.preventDefault()
										} // disable right click
										url={response} // your props
										className='modal__video-style'
										controls
										width='100%'
										height='100%'
									/>
								</div>
							</div>
						</div>
					</section>
				) : null}
			</button>
		</div>
	);
}
