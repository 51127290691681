import './App.css';
import { React } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import LoginScreen from './screens/auth/login/login';
import ForgetPasswordScreen from './screens/auth/forgetPassword/ForgetPassword';
import Container from './screens/Dashboard/global/container';
import { useAuthContext } from './hooks/useAuthContext';
import Registration from './screens/auth/registration/registration';
import Introduction1 from './screens/intro/Introduction1';
import OtpVerification from './screens/auth/otpVerification/otpVerificaton';

function App() {

  const { user } = useAuthContext();
  
  return (
    <div className="App">
      <div className='body'>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={ <Introduction1 /> } />
            <Route path='/login' element={!user ? <LoginScreen /> : <Navigate to="/home/" />} />
            <Route path='/home/*' element={ user ? <Container /> : <Navigate to="/login" /> } /> 
            <Route path='/forget-password' element={<ForgetPasswordScreen />} />
            <Route path='/register' element={<Registration />}/>
            <Route path='/verifyotp' element={<OtpVerification />}/>
          </Routes>
        </BrowserRouter>
      </div>
    </div>
  );
}

export default App;
