
import react from 'react';

import img1 from "../../../assets/Pck/JGM-cover.jpg";

let packageTest = [
    {
        "id": 1,
        "Title": "Jana Gana Mana",
        "noOfVideos": 15,
        "noOfVideosWatched": 10,
        "thumbnails": img1
    },
    
]


export default packageTest;