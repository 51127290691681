import { React } from 'react';

// Importing Images
import Logo from '../../../assets/global/logo.png'; 
import forgetPassword from '../../../assets/auth/forget/forgot-password.png';

// styling Sheet
import '../../../App.css';

function ImageContainer() {
    return (
        <>
            <div className='logo'>
                    <img src={Logo} alt="logo" />
                </div>
                <div className='left-image'>
                <img src={forgetPassword} alt='forget-password'/>
            </div>
        </>
    );
}

export default ImageContainer;