import { React, useState} from 'react';

// Material UI components
import { IconButton } from '@mui/material/';

// Image and Icons
import { Notifications } from '@mui/icons-material/';
import hand from '../../../../assets/dashboard/hand.png';

// CSS/Styling files
import './style/header.css';

// Javascript Packages
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';

// Service Files
import ManageAccount from './ManageAccount';
import { useAuthContext } from '../../../../hooks/useAuthContext';


const Header = () => {
    const { dispatch } = useAuthContext();

    const token = Cookies.get('authToken');

    const decode = jwtDecode(token);

    console.log(decode);

    const firstName = decode.firstName;
    const lastName = decode.lastName;
    const email = decode.email;
    
    // userService(token).then(result => {
    //     setFirstName(result.firstName)
    //     setLastName(result.lastName)
    //     setEmail(result.email)
    // }).catch(e => console.log(e));

    const removeauth = async() => {
        Cookies.remove("authToken");
        dispatch({type: 'LOGOUT'});
    };

  return (
    <div className='header'>
                {/* Text Box */}
                <div className='text-box'>
                    {/* User Name */}
                    <h1>Hello
                        <br />
                    <span>{firstName + " " +lastName}</span>
                    </h1>
                    {/* Emoji added */}
                    <div className='emoji'>
                        <img src={hand} alt='hand' />
                    </div>
                </div>

                {/* User Icons */}
                <div className='icon-box'>
                    <IconButton aria-label='Notification Button'><Notifications className='notification-icon'/></IconButton>
                        <div style={{marginLeft: "1rem"}}><ManageAccount name={firstName + " " + lastName} mail={email} onClick={removeauth}/></div>
                </div>
            </div>
  )
}

export default Header;