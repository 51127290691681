// importing the coursecard css for styles

import "./styles/CourseCard.css";
import React from "react";
import { useState } from "react";
import YouTubeIcon from "@mui/icons-material/YouTube";

function CourseCard(props) {
  const [width, setWidth] = useState(0);
  React.useEffect(() => {
    setWidth((props.noOfVideos / props.noOfVideos) * 100);
  }, []);

  return (
    <>
      <div className="cardContainer">
        <img
          src={props.thumbnail}
          alt="Course Thumbnail"
          className="CoursesThumbnail"
        />
        <div className="CoursesDescription">
          <div className="CourseTitle">{props.title}</div>
          <div className="CourseButton">
            <a href="#">Continue</a>
          </div>
        </div>
        <div className="CourseTitle">
          <YouTubeIcon />
          <div style={{display:"flex",alignItems:"center"}}>
            {props.noOfVideos} Videos
          </div>
        </div>
      </div>
    </>
  );
}

export default CourseCard;
