import axios from "axios";
import { DEV_URL, LOCAL_URL } from "../../constant";

export const userService = async (auth_token) => {
  const config = {
    headers:{
      "auth-token": auth_token
    }
  };

  try {
    const response = await axios.get(`${DEV_URL}/api/auth/user`, config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
  
};
