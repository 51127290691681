// React Packages
import { React } from 'react';

// Material UI Components
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers-pro';
import { IconButton, Rating, LinearProgress } from '@mui/material/';

// Image and Icons
import { ArrowCircleLeft, ArrowCircleRight } from '@mui/icons-material/';

import img from "../../assets/Pck/JGM-cover.jpg";
// CSS Import 
import './style/dashboard.css';
import { useNavigate } from 'react-router-dom';

function DashBoard() {
    const navigate = useNavigate();
    return (
            <div className='body-home'>
                <div className='courses-box'>
                    <h2>Course Activity</h2>
                    <div className='courses-container'>
                        <div className='course' onClick={()=>{navigate("/home/package")}} style={{cursor:"pointer"}}>
                            <div className='image'>
                                <img src={img} alt="dash" />
                            </div>
                            <div className='content'>
                                <div className='content-heading'>
                                    <h3>Learn Jana Gana Mana on Piano / Keyboard</h3>
                                </div>
                                <p>Learn to Play JANA GANA MANA with Passion, Pride & Precision</p>
                            </div>
                        </div>
                    </div>
                    {/* <div className='courses-container'>
                        <IconButton className='course-left-arrow'><ArrowCircleLeft /></IconButton>
                        <div className='course'>
                            <div className='image'></div>
                            <div className='content'>
                                <div className='content-heading'>
                                    <h3>Course Name</h3>
                                    <h4>Enrolled</h4>
                                    <br />
                                </div>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                <Rating name="read-only" value={3} readOnly className='rating'/>
                                <LinearProgress variant="determinate" value={20} className='progressBar'/>
                            </div>
                        </div>
                        <IconButton className='course-right-arrow'><ArrowCircleRight /></IconButton>
                    </div> */}
                </div>
                <div className='calendar-box'>
                        <h2>Class Schedule</h2>
                    <div className='calendar'>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DateCalendar className='class-calendar' sx= {{width: 2/3, height: "auto"}} />
                        </LocalizationProvider>
                    </div>
                </div>
            </div>
    );
}

export default DashBoard;