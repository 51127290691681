import axios from "axios";
import { DEV_URL, LOCAL_URL } from "../../constant";

export const loginService = async (loginData) => {
  try {
    const response = await axios.post(`${DEV_URL}/api/jana-gana-mana/login`, loginData);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
