import axios from "axios";
import { DEV_COURSE_URL } from "../../constant";
export const courseservice = async () => {
  try {
    const response = await axios.get(
      `${DEV_COURSE_URL}/api/courses/get-all-courses`
    );
    return response.data;
  } catch (error) {}
};
