// React Packages
import { React, useState } from "react";
// importing the styles for evaluation
import "./styles/Evaluation.css";
// AntD Component
import { Skeleton } from "antd";
// importing the result cards for evaluation
import Result from "../Dashboard/global/component/resultscard/Result";
// Javascript Packages
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function Evaluation() {
	const token = Cookies.get("authToken");
	const decode = jwt_decode(token);
	const location = useLocation();
	const pieceid = location.state.pieceid;
	const [selectedFile, setSelectedFile] = useState(null);
	const [response, setResponse] = useState(null);
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const handleFileChange = (event) => {
		setSelectedFile(event.target.files[0]);
	};

	const uploadAudio = () => {
		if (selectedFile) {
			setLoading(true);
			const formData = new FormData();
			formData.append("user-audio-file", selectedFile);
			formData.append("user-id", decode.userId);
			formData.append("piece-id", pieceid);
			axios
				.post(
					"https://mp3.janaganamana.lorrainemusicacademy.org/get_json_output/",
					formData
				)
				.then((response) => {
					setResponse(response.data);
				})
				.catch((error) => {
					console.error("Error uploading file:", error);
					setResponse(null);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	return (
		<div className='EvaluationBody'>
			<div className='EvaluationWrapper'>
				<div className='EvaluationDesc'>
					{/* declaring the headings for audio evaluation */}
					<div className='EvaluationHeading'>
						<ArrowBackIcon
							style={{ cursor: "pointer" }}
							onClick={() => navigate("/home/videos")}
						/>
						<h2>Audio Evaluation</h2>
					</div>
					<div className='EvaluationButtons'>
						{/* button for uploading the audio */}
						<div className='EvaluationButton1'>
							<h2>
								{selectedFile === null
									? "Upload audio for evaluation"
									: selectedFile.name}
							</h2>
							<input
								type='file'
								accept='audio/mpeg'
								onChange={handleFileChange}
								className='FileInput'
							/>
						</div>
						<button
							className='EvaluationButton2'
							onClick={uploadAudio}>
							<h2>Upload Audio</h2>
						</button>
					</div>
				</div>
				<img
					className='EvaluationPhoto'
					src={location.state.thumbnail}
					alt='Evaluation Thumbnail'
				/>
			</div>
			{/* this component makes the result cards */}
			{loading === true ? (
				<div id='evaluation-result-loading-wrapper'>
					<Skeleton active />
				</div>
			) : (
				<div></div>
			)}
			{response !== null ? (
				<div id='evaluation-result-wrapper'>
					<Result
						title='Your score has been Evaluated!'
						notesPlayed={response.extracted_notes}
						actualNotes={response.master_notes}
						accuracy={response.audio_score}
						pieceid={pieceid}
					/>
				</div>
			) : (
				<div></div>
			)}
		</div>
	);
}

export default Evaluation;
