import axios from "axios";
import { DEV_COURSE_URL } from "../../constant";

export const markVideoCompleted = async (lessonsDetails) => {
	try {
		const response = await axios.put(
			`${DEV_COURSE_URL}/api/student-progress/mark-video-completed`,
			lessonsDetails
		);
		console.log(response);
		return "the next video was unlocked";
	} catch (error) {
		// returning blank array after getting server and internet error
		return "some error has occured";
	}
};
