import React from "react";

// importing the course cards for listing the courses
import CourseCard from "../Dashboard/global/component/coursecard/CourseCard";

// importing the css for course dashboard page
import "./styles/CourseDashboardPage.css"

// importing the cookies library
import Cookies from "js-cookie";

// using the react router dom for the navigation
import { useNavigate } from "react-router-dom";

// importing the courses list from course services
import { courseservice } from "../../service/courses/CourseService";

import packageTest from "./data/pacakge";

// import img from "../../assets/package/"


function CoursePackage() {

  // used selected row for detecting the clicked courses
  const [selectedRow, setSelectedRow] = React.useState();

  // used response for getting response from courseservice
  const [response, setresp] = React.useState("");

  // used the error for catching error from courseservice
  const [error, seterr] = React.useState("");
  
  React.useEffect(() => {
    // calling the course service for getting course list
    courseservice().then(setresp).catch(seterr);
  }, []);
  const navigate = useNavigate();

  // making the object of the course list
  const valuees = Object.values(response); 

  // setting this for getting courseid inside selectedrow
  if (typeof selectedRow === "number") {

    // adding the updated courseid into cookies
    Cookies.remove("coursesid");
    Cookies.set("coursesid", selectedRow);
    navigate("/home/videos");
  }

  return (
    <div className="CourseDashboard">
      <h2>Enrolled Courses</h2>
      <div className="CoursesList">
        {/* {valuees.slice(0, 5).map((response) => { */}
        {packageTest.map((response) => {
          return (
            <button
              style={{ backgroundColor: "transparent", border: "none" }}
              onClick={() => setSelectedRow(response.id)}
            >
              <CourseCard
                key={response.id}
                title={response.Title}
                noOfVideos={response.noOfVideos}
                noOfVideosWatched={response.noOfVideosWatched}
                thumbnail={response.thumbnails}
              />
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default CoursePackage;
