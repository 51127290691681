import { React } from 'react';

// Importing Images
import Logo from '../../../assets/global/logo.png'; 
import welcome from '../../../assets/auth/Login/welcome.png';
import loginimg from '../../../assets/auth/Login/janaganamana-login.png';

// styling Sheet
import '../../../App.css';

function ImageContainer() {
    return (
        <>
            <div className='logo'>
                    <img src={Logo} alt="logo" />
            </div>
            <div className='left-image'>
                    <img src={loginimg} alt="Login" />
            </div>
        </>
    );
}

export default ImageContainer;