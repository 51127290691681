// React Packages
import { React, useState } from 'react';

// Styling Sheet
import './style/Profile.css';
import './style/ProfileEdit.css';

// Material Icons
import { FacebookRounded, YouTube, Instagram, Twitter } from '@mui/icons-material/';

// Material UI Components
import { IconButton } from '@mui/material';

// Dummy Image 
import TestImage from '../../assets/test/test-1.png';

// Javascript Packages
import Cookies from 'js-cookie';

// Service Files
import { userService } from '../../service/user/userdata';
import { modifyStudentProfile } from '../../service/user/studentService';

const ProfileEdit = () => {

  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [dob, setDOB] = useState("")
  const [mobile, setMobile] = useState("")
  const [userEmail, setUserEmail] = useState("")

  const [studentBio, setStudentBio] = useState("")
  const [studentTwitter, setStudentTwitter] = useState("")
  const [studentFacebook, setStudentFacebook] = useState("")
  const [studentYoutube, setStudentYoutube] = useState("")
  const [studentInstagram, setStudentInstagram] = useState("")

  const token = Cookies.get('authToken');
    
  userService(token).then(result => {
    setFirstName(result.firstName)
    setLastName(result.lastName)
    setDOB(result.dob)
    setMobile(result.phoneNo)
    setUserEmail(result.email)
}).catch(e => console.log(e));

const handleSubmit = async (event) => {
  
  const studentData = { 
    userEmail: userEmail,
    isActive: true,
    studentBio: studentBio,
    twitterLink: studentTwitter,
    youtubeLink: studentYoutube,
    facebookLink: studentFacebook,
    instagramLink: studentInstagram,
  }

  const data = await modifyStudentProfile(studentData);

  console.log(data.msg);
    if(data.msg === 'Successful'){
  }

  setStudentBio("")
  setStudentTwitter("")
  setStudentFacebook("")
  setStudentInstagram("")
  setStudentYoutube("")

  return data.msg;
}

  return (
        <div className='profile-container'>
          <div className='Profile-section-1'>
            <img src = {TestImage} alt="Profile Display"/>
            <div className='Profile-section-1-text'>
              <span>{firstName} {lastName}</span>
              <span>{dob}</span>
              <span>{mobile}</span>
              <span>{userEmail}</span>
            </div>
            <button className='pe-button' onClick={handleSubmit}>Save</button>
          </div>
          <div className='Profile-section-2'>
            <h2>My Profile</h2>
            <br/>
            <br/>
            <textarea name="bio" rows="4" cols="50" placeholder='Enter your profile bio (less than 300 words)'>
              
            </textarea>
            <br/>
            </div>
            <div className='Profile-section-2 Profile-section-3'>
              <ul className='social-links'>
                <li><IconButton>
                  <Instagram className='user-social-media-icon' />
                </IconButton><input type='text' placeholder='instagram'/></li>
                <li><IconButton>
                  <FacebookRounded className='user-social-media-icon' />
                </IconButton><input type='text' placeholder='facebook'/></li>
                <li><IconButton>
                  <YouTube className='user-social-media-icon' />
                </IconButton><input type='text' placeholder='youtube'/></li>
                <li><IconButton>
                  <Twitter className='user-social-media-icon' />
                </IconButton><input type='text' placeholder='twitter'/></li>
              </ul>
            </div>
          </div>
  )
}

export default ProfileEdit