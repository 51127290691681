// React Packages
import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// AntD UI Components
import { message } from 'antd';

// Material UI Packages
import Grid from "@mui/material/Grid";
import { TextField, Button } from "@mui/material/";

// Importing CSS
import "./styles/registration.css";

// LMA Packages
import RegistrationLeftSide from "./containerImg";

// Services
import { signUpService } from "../../../service/auth/signUpService";

function Registration() {

    const [firstName, setFirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [mobile, setMobile] = useState();
    const [password, setPassword] = useState();
    const [confirmPassword, setconfirmPassword] = useState();
    const [country, setCountry] = useState();
    const [city, setCity] = useState();
    const [dob, setDOB] = useState();

    const navigate = useNavigate();
    
    const handleSubmit = async (event) => {
      const userData = { 
        firstName: firstName,
        middleName: "",
        lastName: lastName,
        email: email, 
        mobile: mobile,
        password: password,
        confirmPassword: confirmPassword,
        country: country,
        city: city,
        dob: dob,
      }

      if(firstName === undefined ||
        lastName === undefined ||
        email === undefined ||
        mobile === undefined ||
        password === undefined ||
        confirmPassword === undefined ||
        country === undefined ||
        city === undefined ||
        dob === undefined){
          message.error("Fill all the required fields!");
        }else{

          const data = await signUpService(userData);
         
          navigate("/verifyotp", {state: {
            userid: data.id
          }
        });
      }
      
      // return data.msg; 
    }

  return (
    <div id="master-wraper" className="register-wraper">
      <div className="left-wraper">
        <RegistrationLeftSide />
      </div>

      <div className="right-wraper">
        <div className="login-box">
          <div className="text-box">
            <h1>Register</h1>
            <p className="sub-heading">
              Register Now to Learn & Play JANA GANA MANA on Piano!
            </p>
          </div>
          <div className="contant-wraper register">
            <Grid container spacing={1} display="flex" justifyContent="center">
              <Grid item xs={12} sm={6}>
                <TextField required fullWidth label="First Name" className="fieldset" value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required fullWidth label="Last Name" className="fieldset" value={lastName} onChange={(e) => setLastName(e.target.value)} />
              </Grid>
              <Grid item xs={12} sm={13}>
                <TextField
                  required
                  fullWidth
                  value={email} onChange={(e) => setEmail(e.target.value)}
                  label="Email Address" className="fieldset"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  value={mobile} onChange={(e) => setMobile(e.target.value)}
                  label="Mobile Number" className="fieldset"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  value={dob} onChange={(e) => setDOB(e.target.value)}
                  label="Date of Birth (DD/MM/YYYY)"
                   className="fieldset"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required fullWidth label="Password" className="fieldset" type="password" value={password} onChange={(e) => setPassword(e.target.value)}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  label="Confirm Password" className="fieldset"
                  type="password"
                  value={confirmPassword} onChange={(e) => setconfirmPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required fullWidth label="Country" className="fieldset" value={country} onChange={(e) => setCountry(e.target.value)}/>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField required fullWidth label="City" className="fieldset" value={city} onChange={(e) => setCity(e.target.value)}/>
              </Grid>
            </Grid>
            <Button variant="contained" className="full-btn" onClick={handleSubmit}>
            Register
          </Button>
          </div>
          <div className="clear"></div>
        </div>
      </div>
    </div>
  );
}

export default Registration;
