import axios from "axios";
import { DEV_URL, LOCAL_URL } from "../../constant";

export const verifyotpService = async (userid,otp) => {
  try {
    const response = await axios.delete(
      `${DEV_URL}/api/jana-gana-mana/deleteOtp/`+userid+`/`+otp
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
