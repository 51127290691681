import "./styles/LessonCard.css";
import PropTypes from "prop-types";
// importing cookies for getting cookie values
import Cookies from "js-cookie";
function LessonCard(props) {
  const removePrefixAndSuffix = (string) => {
    const prefix = Cookies.get("coursesid");
    return string.slice(prefix.length)
  }
  return (
    <div className="lessonwrapper">
      <img
        className="lessonphoto"
        src={props.thumbnail}
        alt="Course Thumbnail"
      />
      <div className="lessontitle">{removePrefixAndSuffix(props.title)}</div>
    </div>
  );
}


export default LessonCard;
