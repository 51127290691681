// React Packages
import { React, useState } from "react";

// Styling Sheet
import "./style/Profile.css";

// Material Icons
import {
  FacebookRounded,
  YouTube,
  Instagram,
  Twitter,
} from "@mui/icons-material/";

// Material UI Components
import { IconButton } from "@mui/material";

// Dummy Image
import TestImage from "../../assets/test/test-1.png";
// Javascript Packages
import Cookies from "js-cookie";

// Service Files
import { userService } from "../../service/user/userdata";
import { fetchStudentProfile } from "../../service/user/studentService";

const Profile = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDOB] = useState("");
  const [mobile, setMobile] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const token = Cookies.get("authToken");

  userService(token)
    .then((result) => {
      setFirstName(result.firstName);
      setLastName(result.lastName);
      setDOB(result.dob);
      setMobile(result.phoneNo);
      setUserEmail(result.email);
    })
    .catch((e) => console.log(e));

  let [bio, setBio] = useState("");
  let [youtube, setYoutube] = useState("");
  let [twitter, setTwitter] = useState("");
  let [instagram, setInstagram] = useState("");
  let [facebook, setFacebook] = useState("");

  fetchStudentProfile(token)
    .then((result) => {
      setBio(result.studentBio);
      setFacebook(result.facebookLink);
      setInstagram(result.instgramLink);
      setYoutube(result.youtubeLink);
      setTwitter(result.twitterLink);
    })
    .catch((e) => console.log(e));

  const dummyBio =
    "👋 Hi there! It looks like you haven't added a bio to your profile yet. No worries, you can take this opportunity to let others know more about your love of music and what you're interested in. Share a bit about your favorite genres, artists, or any fun facts about music that make you unique. Your bio is a great way to connect with other music lovers and spark interesting conversations. Take your time to customize it and make it your own! If you need any inspiration or guidance, feel free to ask. Let's make your bio stand out and showcase the amazing music fan you are!";

  return (
    <div className="profile-container">
      <div className="Profile-section-1">
        <img src={TestImage} alt="Profile Display" className="ProfileTestImage"/>
        <div className="Profile-section-1-text">
          <span>{firstName + " " + lastName}</span>
          <span>{dob}</span>
          <span>{mobile}</span>
          <span>{userEmail}</span>
        </div>
        <div id="user-social-media-links">
          {facebook === "" ? (
            <FacebookRounded className="user-social-media-icon" />
          ) : (
            <IconButton>
              <FacebookRounded
                className="user-social-media-icon"
                sx={{ color: "#3b5998" }}
              />
            </IconButton>
          )}
          {youtube === "" ? (
            <YouTube className="user-social-media-icon" />
          ) : (
            <IconButton>
              <YouTube
                className="user-social-media-icon"
                style={{ color: "#cd201f" }}
              />
            </IconButton>
          )}
          {instagram === "" ? (
            <Instagram className="user-social-media-icon" />
          ) : (
            <IconButton>
              <Instagram
                className="user-social-media-icon"
                style={{ color: "#FD297B" }}
              />
            </IconButton>
          )}
          {twitter === "" ? (
            <Twitter className="user-social-media-icon" />
          ) : (
            <IconButton>
              <Twitter
                className="user-social-media-icon"
                sx={{ color: "#00acee" }}
              />
            </IconButton>
          )}
        </div>
      </div>
      <div className="Profile-section-2">
        <h2>My Profile</h2>
        <p>{bio === undefined ? dummyBio : bio}</p>
      </div>
    </div>
  );
};

export default Profile;
