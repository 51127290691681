// importing useState and useEffect for updating variables
import React, { useState, useEffect } from "react";
// importing cookies for getting cookie values
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
// importing lessoncard components for listing lessons
import LessonCard from "../Dashboard/global/component/lessoncard/LessonCard";
// importing the video service for getting video lists
import { videoservice } from "../../service/videos/VideoService";
import { getCompletedLessonIDs } from "../../service/dripFeeding/GetCompletedLessonIDs";
// importing useNavigation for navigating to videos screen
import { useNavigate, Link } from "react-router-dom";
// importing the style sheets for styling the screen
import "./styles/CoursePage.css";
import YouTubeIcon from "@mui/icons-material/YouTube";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import VideoPlayerPopup from "../player/videoPlayerPopup";
import img from "../../assets/Pck/JGM-cover.jpg";
import pdf from "../../assets/Jana-Gana-Mana-Sheet.pdf";

export default function CoursePage() {
	const [completedVideosList, setCompletedVideosList] = useState([]);
	const decode = jwtDecode(Cookies.get("authToken"));

	// response keep the values that we get from videoservice
	const [response, setresp] = useState("");
	useEffect(() => {
		const body = document.querySelector("body");
		if (window.location.pathname === "/home/videos") {
			body.style.overflowY = "scroll";
		}

		const coursesid = Cookies.get("coursesid");
		videoservice(coursesid).then(setresp);
		const decode = jwtDecode(Cookies.get("authToken"));
		let coursesDetails = { studentId: decode.userId, courseId: 1 };
		getCompletedLessonIDs(coursesDetails).then(setCompletedVideosList);
	}, []);
	const navigate = useNavigate();
	const valuees = Object.values(response);
	return (
		<div className='coursePage'>
			{/* coursepage info contains upper part with thumbnail */}
			<div className='CoursepageInfo'>
				{/* coursepage desc contains upper part without thumbnail */}
				<div className='CoursepageDesc'>
					<div className='coursePageName'>
						<ArrowBackIcon
							className='back-btn'
							onClick={() => navigate("/home/package")}
						/>
						<h2>
							Learn to play JANA GANA MANA with Passion, Pride &
							Precision
						</h2>
					</div>
					<div className='CoursepageTitle'>
						<YouTubeIcon />
						<span
							style={{
								marginLeft: "5px",
								display: "flex",
								alignItems: "center",
							}}>
							15 Lessons
						</span>
					</div>
					<p className='AboutCourse'>
						On 14th August 2023, in a one-hour online concert
						streamed at midnight on the eve of{" "}
						<strong>
							India's 76<sup>th</sup> year of Independence
						</strong>
						, Indians, Indian diaspora and friends of India played
						the Indian National Anthem from 5 continents and
						different corners of the world - USA, Austria, France,
						Spain, Germany, Norway, Australia and of course from
						India. The instruments the anthem was played on were
						Sitar, Violin, Cello, Guitar, Grand Piano and Keyboard.
					</p>
					<p className='AboutCourse'>
						This concert is organized by Lorraine Music Academy in
						an effort to teach the{" "}
						<strong>
							correct version of the National Anthem of India, to
							Indians, Indian diaspora and Indophiles around the
							world.
						</strong>
					</p>
					<p className='AboutCourse'>
						This is an ongoing movement. Please share freely and
						widely across all your entire networks globally to
						invite everyone to participate, learn and enjoy…🙏🙏
					</p>
					<Link
						to={pdf}
						target='_blank'
						className='CoursepageContButton'>
						Download Book
					</Link>
				</div>
				<div className='course-img'>
					<img
						src={img}
						alt='thumbnail'
						className='CoursepageThumbnail'
					/>
				</div>
			</div>
			<div className='LessonsDesc'>
				<h2>Lessons</h2>
			</div>
			<div className='lessons-cont'>
				{valuees.map((response) => {
					const isUnlocked =
						response.id === 7 ||
						response.id === 8 ||
						response.id === 9 ||
						decode.email === "aubrey@lorrainemusicacademy.com"
							? true
							: completedVideosList === undefined
							? false
							: completedVideosList.includes(response.id)
							? true
							: false;
					return (
						<div
							key={response.id}
							className={
								isUnlocked
									? "videoevaluation"
									: "videoevaluationBlocked"
							}>
							<LessonCard
								key={response.id}
								title={response.videoTitle}
								thumbnail={response.imgURL}
							/>
							<div className='audiowrapper'>
								<VideoPlayerPopup
									videosname={response.videoTitle}
									isUnlocked={isUnlocked}
								/>
								{response.evaluation === true ? (
									<button
										disabled={isUnlocked ? false : true}
										className='course-ev'
										onClick={() =>
											navigate("/home/evaluation", {
												state: {
													pieceid: response.id,
													thumbnail: response.imgURL,
												},
											})
										}>
										Evaluation
									</button>
								) : null}
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
}
