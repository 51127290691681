import axios from "axios";
import { DEV_URL, LOCAL_URL } from "../../constant";

export const signUpService = async (SignUpData) => {
  try {
    const response = await axios.post(`${DEV_URL}/api/jana-gana-mana/create-user`, SignUpData);
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
