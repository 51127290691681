import { React, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import "./style/container.css";

import Sidebar from "./sidebar";
import DashBoard from "../Dashboard";
import Profile from "../../Profile/Profile";
import CoursePage from "../../videos/CoursePage";
import VideoPlayer from "../../player/VideoPlayer";
import ProfileEdit from "../../Profile/ProfileEdit";
import Evaluation from "../../evaluation/Evaluation";
import CoursePackage from "../../package/CoursePackage";
import MobileNavbar from "./component/MobileNavbar/MobileNavbar";
// Components
import Header from "./component/header";

import Cookies from "js-cookie";
import decode from "jwt-decode";

function Container() {
  return (
    <div id="dashboard-container">
      <div className="mob">
        <MobileNavbar/>
      </div>
      <div className="list-container">
        <Sidebar />
      </div>
      <div className="component-container">
        <Header />
        <Routes>
          <Route path="" element={<DashBoard />} />
          <Route path="/ProfileEdit" element={<ProfileEdit />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/package" element={<CoursePackage />} />
          {/* <Route path="/package/courses" element={<CoursePackage2 />} /> */}
          {/* <Route path="/package/courses/course" element={<I2m1 />} /> */}
          {/* <Route path="/courses" element={<CourseDashboardPage />} /> */}
          <Route path="/videos" element={<CoursePage />} />
          <Route path="/player" element={<VideoPlayer />} />
          <Route path="/evaluation" element={<Evaluation />} />
        </Routes>
      </div>
    </div>
  );
}

export default Container;
