import axios from "axios";
import { DEV_COURSE_URL } from "../../constant";

export const videoservice = async (videosid) => {
  try {
    const response = await axios.get(
      `${ DEV_COURSE_URL }/api/anthems/fetch-anthems/` + videosid
    );
    return response.data;
  } catch (error) {}
};
