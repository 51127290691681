import { React, useState } from "react";
import "./styles/MobileNavbar.css";
import { Link } from "react-router-dom";
import { SideBarData } from "../../data/items";
import { useAuthContext } from "../../../../../hooks/useAuthContext";
import Cookies from "js-cookie";

function MobileNavbar() {
  const { dispatch } = useAuthContext();
  const [selected, setSelected] = useState("/home");
  const removeauth = async () => {
    Cookies.remove("authToken");
    dispatch({ type: "LOGOUT" });
  };
  return (
    <nav role="MobNavbarNavigation">
      <div id="menuToggle">
        <input type="checkbox" />
        <span></span>
        <span></span>
        <span></span>
        <ul id="menu">
          {SideBarData.map((item, index) => {
            return (
              <div key={index} onClick={() => setSelected(item.to)}>
                {/* <Link to={item.to}> */}
                <a href={item.to}><li>{item.heading}</li></a>
                {/* </Link> */}
              </div>
            );
          })}
          <Link to="/login" onClick={removeauth}>
            <li>Logout</li>
          </Link>
        </ul>
      </div>
    </nav>
  );
}

export default MobileNavbar;
