const LOCAL_URL = "http://localhost:8080";
const DEV_URL = "https://auth.janaganamana.lorrainemusicacademy.org";
const DEV_COURSE_URL = "https://courses.janaganamana.lorrainemusicacademy.org";
// const DEV_COURSE_URL = "http://localhost:8080";
const PROD_URL = "https://auth.lorrainemusicacademy.org";

const REACT_APP_TRELLO_LIST_ID = "646342fa4e5d81cc206a61ae";
const REACT_APP_TRELLO_API_KEY = "3586b25ea835c7c51c2fc3de75484f9b";
const REACT_APP_TRELLO_SECRET_KEY =
	"8594f7fdee712abaa1b554c8681eb91341a31e02e59f4c51da626a5adf629a38";
const REACT_APP_TRELLO_TOKEN =
	"ATTA42b11cd5a1e5b1fc0fe39006bde20daeee6404039d223dd1d9d225dcbd2a342906273F20";

module.exports = {
	LOCAL_URL,
	DEV_URL,
	PROD_URL,
	DEV_COURSE_URL,
	REACT_APP_TRELLO_TOKEN,
	REACT_APP_TRELLO_API_KEY,
	REACT_APP_TRELLO_LIST_ID,
	REACT_APP_TRELLO_SECRET_KEY,
};
