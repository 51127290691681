// React Packages
import { React, useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

// Material UI Packages
import { TextField, Checkbox, Button, FormGroup, FormControlLabel } from '@mui/material/';

// AntD UI Components
import { message } from 'antd';

// Javascript Packages
import Cookies from 'js-cookie';

// LMA Packages
import ImageContainer from '../login/containerImg';

// Service Files
import { loginService } from '../../../service/auth/loginService';
// AuthContext
import { useAuthContext } from '../../../hooks/useAuthContext';

// Stylling Sheet

function LoginScreen() {
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const { dispatch } = useAuthContext();

    const navigate = useNavigate();
    Cookies.set('isLogin',false);
    
    const handleSubmit = async (event) => {
        if(email !== undefined && email.match(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)){
            const userData = { 
                email: email, 
                password: password 
            }
            // console.log(userData);
            const data = await loginService(userData);
            // console.log(data.msg);
            if(data.message === 'Successful'){
                Cookies.set('authToken', data.authToken);
                dispatch({type: 'LOGIN', payload: data.authToken});
                navigate("/home");
            }else{
                    if(email !== '' && password !== ''){ 
                        message.error(data.message); 
                    }else{
                        if(email === '' && password === ''){
                            message.error("Please enter your Email-ID and Password");
                        }else if(password === '' && email !== ''){
                            message.error("Please enter your Password");
                        }else{
                            message.error("Please enter your Email-ID");
                        }
                    }

                setEmail('');
                setPassword('');
            }
            return data.message; 
        }else{
            const userData = { userName: email, password: password }
            // console.log(userData);
            const data = await loginService(userData);
            console.lod(data)
            // console.log(data.msg);
            if(data.message === 'Successful'){
                Cookies.set('authToken', data.authToken);
                dispatch({type: 'LOGIN', payload: data.authToken});
                navigate("/home");
            }else{
                    if(email !== '' && password !== ''){ 
                        message.error(data.msg); 
                    }else{
                        if(email === '' && password === ''){
                            message.error("Please enter your Email-ID and Password");
                        }else if(password === '' && email !== ''){
                            message.error("Please enter your Password");
                        }else{
                            message.error("Please enter your Email-ID");
                        }
                    }

                setEmail('');
                setPassword('');
            }
            return data.msg;
        }
    };

    const handleKeyDown = async (event) => {
        // event.preventDefault();
        if(event.keyCode === 13){
            if(email !== undefined && email.match(/^[a-zA-Z0-9.+_-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)){
                const userData = { email: email, password: password }
                // console.log(userData);
                const data = await loginService(userData);
                // console.log(data.msg);
                if(data.message === 'Successful'){
                    Cookies.set('authToken', data.authToken);
                    dispatch({type: 'LOGIN', payload: data.authToken});
                    navigate("/home");
                }else{
                        if(email !== '' && password !== ''){ 
                            message.error(data.message); 
                        }else{
                            if(email === '' && password === ''){
                                message.error("Please enter your Email-ID and Password");
                            }else if(password === '' && email !== ''){
                                message.error("Please enter your Password");
                            }else{
                                message.error("Please enter your Email-ID");
                            }
                        }

                    setEmail('');
                    setPassword('');
                }
                return data.msg; 
            }else{
                const userData = { userName: email, password: password }
                // console.log(userData);
                const data = await loginService(userData);
                // console.log(data.msg);
                if(data.msg === 'Successful'){
                    Cookies.set('authToken', data.authToken);
                    dispatch({type: 'LOGIN', payload: data.authToken});
                    navigate("/home");
                }else{
                        if(email !== '' && password !== ''){ 
                            message.error(data.msg); 
                        }else{
                            if(email === '' && password === ''){
                                message.error("Please enter your Email-ID and Password");
                            }else if(password === '' && email !== ''){
                                message.error("Please enter your Password");
                            }else{
                                message.error("Please enter your Email-ID");
                            }
                        }

                    setEmail('');
                    setPassword('');
                }
                return data.msg;
            }
        }
    };

    const [checked, setChecked] = useState(true);
    const handleChange = (event) => { setChecked(event.target.checked); };

    return (
        <div id='master-wraper'> 
            <div className='left-wraper'> 
                <ImageContainer /> 
            </div>
            <div className='right-wraper'>
                <div className='login-box'>
                    {/* Text Box */}
                    <div className='text-box'> 
                        <h1>Login</h1> 
                        <p className='sub-heading'>Please Login to Continue!</p>
                    </div>
                    {/* Input Fields */}
                    <div className='contant-wraper'>
                        <TextField id="outlined-basic" label="Email" variant="outlined" type="email" className='fieldset' value={email} onChange={(e) => setEmail(e.target.value)} style={{marginBottom:"3%"}}/>
                        <TextField id="outlined-basic" label="Password" variant="outlined" type="password" className='fieldset' value={password} onChange={(e) => setPassword(e.target.value)} onKeyDown={handleKeyDown} />
                        <FormGroup className='text-1'>
                            <FormControlLabel control={<Checkbox checked={checked} size="small" onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />} label="Keep me Logged In" />
                        </FormGroup> 
                        <Link to="/forget-password"> <span className='text-2'>Forget Password?</span> </Link>
                    </div>
                    {/* Submit Button */}

                    <Button variant="contained" className='full-btn' onClick={() => { handleSubmit(); }}> Login! </Button>

                </div>
            </div>
            <div className='clear'></div>
        </div>
  )
}
export default LoginScreen;