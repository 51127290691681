import React from "react";
import ReactPlayer from "react-player";
import Cookies from "js-cookie";
import { urlservice } from "../../service/urlservice/UrlService";
import VideoPlayerPopup from "./videoPlayerPopup";

const VideoPlayer = () => {
  // const [response, setresp] = React.useState("");
  // const [error, seterr] = React.useState("");
  // React.useEffect(() => {
  //   const coursesid = Cookies.get("coursesid");
  //   const videoname = Cookies.get("videoname");
  //   urlservice(coursesid, videoname).then(setresp).catch(seterr);
  // }, []);
  return (
    <div>
      <VideoPlayerPopup />
      {/* <ReactPlayer url={response} controls /> */}
    </div>
  );
};

export default VideoPlayer;
