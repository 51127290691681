import axios from "axios";
import { DEV_URL } from "../../constant";

export const fetchStudentProfile = async (auth_token) => {
  const config = {
    headers: {
      "auth-token": auth_token,
    },
  };

  try {
    const response = await axios.get(`${DEV_URL}/api/students/profile`, config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const modifyStudentProfile = async (studentData) => {
  try {
    const response = await axios.post(
      `${DEV_URL}/api/students/profile/edit`,
      studentData
    );
    return response.data;
  } catch (error) {
    console.error(error);
  }
};
