import { React, useState } from "react";
import { Link } from "react-router-dom";
import ExitToAppOutlined from "@mui/icons-material/ExitToAppOutlined";
import { useAuthContext } from "../../../hooks/useAuthContext";
import "./style/container.css";

import Logo from "../../../assets/global/logo.png";

import { SideBarData } from "./data/items";
import Cookies from "js-cookie";

function Sidebar() {
  const { dispatch } = useAuthContext();
  const [selected, setSelected] = useState("/home");
  const removeauth = async () => {
    Cookies.remove("authToken");
    dispatch({ type: "LOGOUT" });
  };
  return (
    <>
      <div className="logo-container">
        <Link to="/home">
          <img src={Logo} alt="logo" />
        </Link>
      </div>
      <div className="mobile-menu">
        {SideBarData.map((item, index) => {
          return (
            <div
              className={
                selected === item.to
                  ? "mobile-menuItems active"
                  : "mobile-menuItems"
              }
              key={index}
              onClick={() => setSelected(item.to)}
            >
              <Link to={item.to}>
                <item.icon />
                <span>{item.heading}</span>
              </Link>
            </div>
          );
        })}

        {/* <div className="mobile-menuItems">
          <Link to="/login" onClick={removeauth}>
            <ExitToAppOutlined />
            <span>Logout</span>
          </Link>
        </div> */}
      </div>
      <div className="menu">
        {SideBarData.map((item, index) => {
          return (
            <div
              className={
                selected === item.to ? "menuItems active" : "menuItems"
              }
              key={index}
              onClick={() => setSelected(item.to)}
            >
              <Link to={item.to}>
                <item.icon />
                <span>{item.heading}</span>
              </Link>
            </div>
          );
        })}

        <div className="menuItems">
          <Link to="/login" onClick={removeauth}>
            <ExitToAppOutlined />
            <span>Logout</span>
          </Link>
        </div>
      </div>
    </>
  );
}

export default Sidebar;
