    // React Packages
import { React } from 'react';
import { Link } from "react-router-dom";
// Material UI Packages
import { TextField, Button } from '@mui/material/';

// Importing CSS 
import './style/forgetPassword.css';

// LMA Packages
import ForgetPasswordImage from '../forgetPassword/containerImg';
function ForgetPasswordScreen(){
    return (
            <div id='master-wraper'> 
                <div className='left-wraper'>
                    <ForgetPasswordImage />
                </div>
                
                <div className='right-wraper'>
                    <div className='login-box'>
                        {/* Text Box */}
                        <div className='text-box'>
                            <h1>Forgot</h1>
                            <p className='sub-heading'>Your Password ?</p>
                        </div>

                        {/* Input Fields */}
                        <div className='contant-wraper'>
                            <TextField id="outlined-basic" label="Email" variant="outlined" type="email" className='fieldset'/>
                        </div>

                        {/* Reset Password Button */}

                        <Button variant="contained" className='full-btn'>Reset Password</Button>

                        {/* Back to SingIn */}

                        <div className='sign-in-text'>
                            <span>Or</span>
                            <br />
                            <Link to="/login">
                                <span>Back to Sign-in</span>
                            </Link>
                        </div>

                    </div>
                </div>
            </div>
    );
}

export default ForgetPasswordScreen;
